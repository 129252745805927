export default {
  data: function data() {
    return {
      list: [{
        title: "扫描速度快",
        c: "超高频RFID电子标签可实现多标签同时扫描，速度快，极大地提高了工作效率。",
        icon: require("@/assets/clothing/b/icon1.png")
      }, {
        title: "扫描准确率高",
        c: "每个RFID电子标签都有全球唯一码，扫描准确，避免了重复扫描和漏读的情况。",
        icon: require("@/assets/clothing/b/icon2.png")
      }, {
        title: "上下架效率高",
        c: "手持机扫描贴有电子标签的商品范围广、速度快、更准确，方便商品上架和下架的管理，能够节约时间、减少遗漏和差错，更加轻松。",
        icon: require("@/assets/clothing/b/icon3.png")
      }, {
        title: "物位识别准确",
        c: "通过RFID固定式读写器读取RFID电子标签，保证商品放在正确的位置。",
        icon: require("@/assets/clothing/b/icon4.png")
      }]
    };
  }
};