import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top";
import clothingB from "./components/clothing-b";
import clothingA from "./components/clothing-a";
import clothingC from "./components/clothing-c";
import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "RFID智能仓储管理系统",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "RFID仓储,仓储管理,RFID智能仓储,RFID技术,常达"
    }, {
      name: "description",
      content: "RFID仓储管理系统是基于RFID识别技术为货物识别追踪、管理和查验货物信息的平台。将先进的RFID识别技术和计算机的数据库管理查询相结合，自动识别货物信息，实现企业物流运作的自动化、信息化、智能化的需求。"
    }]
  },
  components: {
    top: top,
    clothingB: clothingB,
    clothingA: clothingA,
    clothingC: clothingC
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  data: function data() {
    return {
      recommend: {
        title: "服装管理产品推荐",
        list: [{
          image: require("@/assets/clothing/recommend/1.png"),
          to: "/product?name=手持机"
        }, {
          image: require("@/assets/clothing/recommend/2.png"),
          to: "/product?name=四通道读写器&current=1"
        }, {
          image: require("@/assets/clothing/recommend/3.png"),
          to: "/product?name=通道门"
        }, {
          image: require("@/assets/clothing/recommend/4.png"),
          to: "/product?name=RFID服装标签&current=1"
        }, {
          image: require("@/assets/clothing/recommend/5.png"),
          to: "/product?name=RFID打印机&current=1"
        }, {
          image: require("@/assets/clothing/recommend/6.png"),
          to: "/product?name=智能收银机"
        }]
      }
    };
  },
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};