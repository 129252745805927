export default {
  data: function data() {
    return {
      controls: false,
      list: [{
        t: "批量群读",
        c: "RFID读写器可以即时读取标签信息，利用超高频读写器，可以瞬间读取上百个标签，极大提高扫描效率，降低人工成本。"
      }, {
        t: "无屏障识别",
        c: "RFID能够穿透纸张、木材、塑料、布匹等材质进行穿透性通信，不需要光源，提供更佳扫描体验，可快速理货、找货、盘点。"
      }, {
        t: "安全性高",
        c: "RFID标签可以为标签数据读写设置密码保护，从而具有更高安全性。使其内容不易被伪造及编造，安全性更高。"
      }, {
        t: "自动出入库",
        c: "手持式读写器稳定读写距离可达2-6米，使用固定式读写器读写距离可达10米以上。有效解决了以往条形码仓储必须手动扫描条形码的问题，实现自动出入库，极大提高仓储效率。"
      }]
    };
  },
  methods: {
    videoPlay: function videoPlay() {
      this.$refs.video.play();
      this.controls = true;
    }
  }
};